









































































































































































import { Component, Watch } from "vue-property-decorator";
import { email, required } from "vuelidate/lib/validators";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import { validationMixin } from "vuelidate";
import { mixins } from "vue-class-component";
import User from "@/models/User.model";
import { AUTH_STORE_NAME, AuthStoreActions, AuthStoreGetters } from "@/store/auth.store";
import { namespace } from "vuex-class";
import { UserDegree } from "@/enum/UserDegree";
import { Gender } from "@/enum/UserTitle.enum";
import { UserRoles } from "@/enum/UserRoles.enum";
import { ROUTE_EMPLOYEE_PORTAL_REQUESTS } from "@/router/routes";

const AuthStore = namespace(AUTH_STORE_NAME);

@Component({
  components: {
    ChangePasswordModal: () =>
      import(
        /* webpackChunkName: "ChangePasswordComponent" */
        "@/components/Authentication/ChangePassword.component.vue"
      ),
    EditUserComponent: () =>
      import(
        /* webpackChunkName: "EditUserComponent" */
        "@/components/EditUser.component.vue"
      ),
    OrderECardComponent: () =>
      import(
        /* webpackChunkName: "EditUserComponent" */
        "@/components/Authentication/OrderECard.component.vue"
        ),
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
})
export default class UserInformationView extends mixins(
  ErrorMessageHandlerMixin
) {
  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user?: User;

  @AuthStore.Action(AuthStoreActions.GET_USER)
  private getUser!: (id: string) => Promise<void>;

  /**
   * flag that handles the change password dialog
   * @private
   */
  private showChangePasswordModal: boolean = false;

  /**
   * flag that handles the edit user dialog
   * @private
   */
  private showEditUserModal: boolean = false;

  private showOrderEasyCardModal: boolean = false;

  private async created() {
    try {
      const id = this.user?._id;
      if (!id) return;

      await this.getUser(id);
    } catch (e) {
      // loads the user in the background, by default uses the cache of the application and updates the user with this
      // call, if it fails, there is no need to handle it
    }
  }

  /**
   * Listen for User changes
   * If the User is not a Regular User, redirect to the Employee Portal
   * @private
   */
  @Watch("user", { immediate: true })
  private onUserChanged() {
    if (this.user === undefined) return;
    if (this.user.role === UserRoles.USER) return;
    this.$router.push({ name: ROUTE_EMPLOYEE_PORTAL_REQUESTS });
  }

  /**
   * returns translated genders based on the gender enum
   * @private
   */
  private get gender() {
    if (!this.user?.gender) return null;
    return this.$t("GENERAL.GENDER." + this.user?.gender?.toString());
  }

  private get isNotSet(): boolean {
    return (
      this.user?.gender === Gender.NOT_SET ||
      this.user?.gender === Gender.UNKNOWN
    );
  }

  /**
   * opens the edit user dialog
   * @private
   */
  private onEditPressed() {
    this.showEditUserModal = true;
  }

  /**
   * opens the order dialog
   * @private
   */
  private onOrderPressed() {
    this.showOrderEasyCardModal = true;
  }

  /**
   * opens the password change dialog
   * @private
   */
  private onChangePasswordPressed() {
    this.showChangePasswordModal = true;
  }

  /**
   * closes the change password dialog
   * @private
   */
  private onPasswordDialogClosed() {
    this.showChangePasswordModal = false;
  }

  /**
   * closes the edit user dialog
   * @private
   */
  private onEditUserDialogClosed() {
    this.showEditUserModal = false;
  }

  /**
   * closes the order Dialog
   * @private
   */
  private onOrderDialogClosed() {
    this.showOrderEasyCardModal = false;
  }

  /**
   * gets the address of the user
   * @private
   */
  private get userAddress() {
    return this.user?.address?.fullAddress;
  }

  /**
   * gets the iban of the user
   * @private
   */
  private get userIBAN() {
    return this.user?.billingDetails?.iban;
  }

  /**
   * gets the bic of the user
   * @private
   */
  private get userBIC() {
    return this.user?.billingDetails?.bic;
  }

  private get degree(): string | null {
    if (!this.user?.degree || this.user?.degree === UserDegree.NONE)
      return null;
    return this.$t(
      "GENERAL.TITLES." + this.user?.degree!.toString()
    ).toString();
  }
}
